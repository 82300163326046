import { toJS } from 'mobx';

import { MessageCenterApi } from 'src/api';
import { conversationData } from 'src/constants';
import { action, makeObservable, observable } from 'src/modules';
import { MessageType } from 'src/utils';
import {
  MessageCenterConversation,
  MessageCenterPerson,
  MessageContent,
} from 'src/utils/types/MessageCenter';

export default class MessageCenterStore {
  @observable
  conversationSelectedId = -1;

  @observable
  conversationCustomerId = 0;

  @observable
  loadingMessages = false;

  @observable
  currentPage = 1;

  @observable
  totalPages = 1;

  @observable
  messageSent = false;

  @observable
  customerSelected: MessageCenterPerson = {
    avatar: '',
    id: 0,
    name: '',
    phone_number: '',
  };

  @observable
  isGuest = false;

  @observable
  conversation: MessageCenterConversation = conversationData;

  @observable
  conversationMessages: MessageContent[] = [];

  @observable
  conversationsList: any[] = [];

  @observable
  conversationSelected = {};

  @observable
  totalUnread = 0;

  @observable
  selectedAssistantUnread = 0;

  @observable
  linkedToUser = false;

  @observable
  hasMorePages = true;

  @observable
  twilioToken = '';

  @observable
  editMessage = {
    converstationBodyId: 0,
    messageBody: '',
    edit: false,
  };

  @observable
  messageTypeId = 0;

  @observable
  lastMessageType = MessageType.IN_APP;

  //https://www.twilio.com/docs/voice/sdks/javascript/twiliodevice#deviceconnectconnectoptions
  /**
   * Attempts a new connection to the Twilio application that you associated with the Access Token used when instantiating the Device instance.
    This method will return a Promise with a Call object. You should keep track of this Call object to monitor/modify the active call.
    To end the call, you can use the .disconnect() method on the Call object or use the device.disconnectAll() method.
   */
  // twilio voice call device
  @observable
  device: any;
  deviceCall: any;

  @observable
  inCall = false;

  @observable
  isNewMessage = false;

  @observable
  isInboxCmsMessages = false;

  constructor() {
    makeObservable(this);
  }

  @action
  setLoadingMessages = (value: boolean) => {
    this.loadingMessages = value;
  };

  @action
  setConversationEmpty = () => {
    this.conversationMessages = [];
  };

  @action
  setCurrentPage = (page = 1) => {
    this.currentPage = page;
  };

  @action
  setTotalPages = (pages = 1) => {
    this.totalPages = pages;
  };

  @action
  setHasMorePages = (value = false) => {
    this.hasMorePages = value;
  };

  @action
  getConversationMessages = async (page: number): Promise<void> => {
    if (this.conversationSelectedId < 0) return;
    this.setLoadingMessages(true);
    this.setLastMessageType(0);
    const response = await MessageCenterApi.getConversationMessages(
      page,
      this.conversationSelectedId,
      this.messageTypeId,
    );

    // set pagination
    this.setCurrentPage(response.current_page);
    this.setTotalPages(response.total_pages);
    this.setHasMorePages(response.current_page < response.total_pages);

    let newData = response.data;
    // this is the place where we set the position to scroll to
    if (response.data.length > 0) {
      newData[response.data.length - 1].scrollerPosition = true;
    }

    if (page > 1) {
      // remove previous scroller position
      const previous = toJS(this.conversationMessages);
      for (let index = 0; index < previous.length; index++) {
        previous[index].scrollerPosition = false;
      }

      newData = [...newData, ...previous];
    } else {
      const len = newData.length - 1;

      if (newData[len] > 0) {
        this.setLastMessageType(newData[len].message_type_id);
      }
    }

    this.setconversationMessages(newData);
    this.setLoadingMessages(false);
  };

  @action
  markMessageAsRead = async (conversation_body_id: number): Promise<void> => {
    for (let index = 0; index < this.conversationMessages.length; index++) {
      if (
        this.conversationMessages[index].conversation_body_id ===
        conversation_body_id
      ) {
        this.conversationMessages[index].is_read = true;
      }
    }

    const conversationsListUpdated = this.conversationsList.map((item: any) => {
      if (item.id === this.conversationSelectedId) {
        item.unread_count_by_conversation -= 1;
      }

      return item;
    });

    let success = true;
    try {
      success = await MessageCenterApi.postMarkRead(conversation_body_id);

      this.totalUnread -= 1;
      this.conversationsList = conversationsListUpdated;
    } catch (error) {
      success = false;
    }
    if (!success) {
      for (let index = 0; index < this.conversationMessages.length; index++) {
        if (
          this.conversationMessages[index].conversation_body_id ===
          conversation_body_id
        ) {
          this.conversationMessages[index].is_read = false;
        }
      }
    }
  };

  @action
  setconversationMessages = (data: MessageContent[]) => {
    this.conversationMessages = data;
  };

  @action
  setLastMessageType = (data: MessageType) => {
    this.lastMessageType = data;
  };

  @action
  loadNextPage = async (): Promise<boolean> => {
    if (this.currentPage >= this.totalPages) return false;
    const nextPage = this.currentPage + 1;
    await this.getConversationMessages(nextPage);
    return true;
  };

  @action
  setConversationMessageSelected = async (id: number) => {
    // if it's a different conversation, clear the messages
    if (this.conversationSelectedId !== id) {
      this.setConversationEmpty();
    }

    // a new conversation was selected
    // load its 1st page of messages
    this.conversationSelectedId = id;
    await this.getConversationMessages(1);
  };

  @action
  sendMessage = async (message: string, type_id: number, files: File) => {
    const data = {
      customer_id: this.conversationCustomerId,
      message_body: message,
      message_type_id: type_id,
      conversation_id: this.conversationSelectedId,
      files,
      is_new_message: this.isNewMessage,
    };

    const result = await MessageCenterApi.sendMessage(data);

    const response = await MessageCenterApi.getConversationMessages(
      1,
      this.isNewMessage ? result.conversation_id : this.conversationSelectedId,
    );

    const lastMessageIndex = response.data.length - 1;
    const lastMessage = response.data[lastMessageIndex];
    this.conversationMessages.push(lastMessage);

    this.isNewMessage = false;
    this.setMessageSent(true);
  };

  @action
  setConversationCustomerId = (id: number) => {
    this.conversationCustomerId = id;
  };

  @action
  setMessageSent = (messageSent: boolean) => {
    this.messageSent = messageSent;
  };

  @action
  setCustomerSelected = (customer: MessageCenterPerson) => {
    this.customerSelected = customer;
  };

  @action
  setConversationsList = (result: MessageCenterConversation) => {
    this.setConversationEmpty();
    this.conversation = result;

    if (this.conversationsList.length && result.current_page > 1) {
      const newData = [...this.conversationsList, ...result.data];
      this.conversationsList = newData;
      return;
    }

    this.conversationsList = result.data || [];
  };

  @action
  setConversationSelected = (conversationSelected: []) => {
    this.conversationSelected = conversationSelected;
  };

  @action
  setTotalUnread = (totalUnread = 0) => {
    this.totalUnread = totalUnread;
  };

  @action
  setSelecteAssistantlUnread = (totalUnread = 0) => {
    this.selectedAssistantUnread = totalUnread;
  };

  @action
  setIsGuest = (guest: boolean) => {
    this.isGuest = guest;
  };

  @action
  setLinkedToUser = (linkedToUser: boolean) => {
    this.linkedToUser = linkedToUser;
  };

  @action
  startVoiceCall = async () => {
    if (this.device) {
      console.log('========= A device exists, no action needed =========');
      return;
    }
    // get a valid twilio token
    const Device = window['Twilio'].Device;
    const response = await MessageCenterApi.getTwilioToken();
    const token = response;

    this.twilioToken = token;
    // console.log(token);

    this.device = new Device(token, { logLevel: 'debug' });
    console.log('Twilio.Device created!');

    this.device.on('error', (twilioError: any, call: any) => {
      console.log('An error has occurred: ', twilioError);
    });

    this.device.on('ready', (device: { edge: any }) => {
      console.log('device.ready:', device.edge);
    });

    this.device.on('connect', (connection: any) => {
      // Enable the hang up button and disable the call buttons
      // If phoneNumber is part of the connection, this is a call from a
      // support agent to a customer's phone
      console.log('connect:' + connection.message);
    });

    this.device.on('disconnect', (connection: any) => {
      // Disable the hang up button and enable the call buttons
      console.log('disconnect:' + connection.message);
    });
  };

  @action
  callCustomer = async (phoneNumber: string) => {
    if (!this.device) {
      console.log('========= A device does not exist!!! =========');
      return;
    }

    const params = {
      to: phoneNumber,
      conversation_id: this.conversationSelectedId,
    };

    console.log('========= Calling customer:', params);
    this.deviceCall = await this.device.connect({ params });

    this.setInCall(true);
  };

  @action
  hangUp = async () => {
    if (!this.device) {
      console.log('========= A device does not exist!!! =========');
    }
    if (!this.deviceCall) {
      console.log('========= A deviceCall dos not exist!!! =========');
    }
    console.log('========= Hanging up =========');
    this.device.disconnectAll();
    this.device.destroy();
  };

  @action
  setEditMessage = (
    converstationBodyId: number,
    messageBody: string,
    edit: boolean,
  ) => {
    this.editMessage = {
      converstationBodyId,
      messageBody,
      edit,
    };
  };

  @action
  setMessageTypeId = (messageTypeId: number) => {
    this.messageTypeId = messageTypeId;
  };

  @action
  setInCall = (inCall: boolean) => {
    this.inCall = inCall;
  };

  @action
  setIsInboxCmsMessages = (isInboxCmsMessages: boolean) => {
    this.isInboxCmsMessages = isInboxCmsMessages;
  };

  @action
  setConversationSelectedId = (conversationSelectedId: number) => {
    this.conversationSelectedId = conversationSelectedId;
  };

  setIsNewMessage = (isNewMessage: boolean) => {
    this.isNewMessage = isNewMessage;
  };
}
