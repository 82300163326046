import request from './request';
import ResponseError from './ResponseError';

export const login = async (
  credentials: Credentials,
): Promise<LoginResponse> => {
  try {
    const { data } = await request.post('/auth/login', credentials);

    return data;
  } catch (error: any) {
    throw new ResponseError(error);
  }
};

export const fetchMe = async (): Promise<User> => {
  try {
    const { data } = await request.get('/auth/me');

    return data.user;
  } catch (error: any) {
    throw new ResponseError(error);
  }
};

export const forgotPassword = async (
  email: string,
): Promise<{ message: string }> => {
  const { data } = await request.post('/auth/forgot-password', { email });

  return data;
};

export const resetPassword = async (
  password: string,
  resetToken: string,
): Promise<{ message: string }> => {
  const { data } = await request.post('/auth/reset-password', {
    password,
    password_confirm: password,
    reset_token: resetToken,
  });

  return data;
};

export const refreshToken = async (): Promise<{ token: string }> => {
  try {
    const { data } = await request.get('/auth/refresh');

    return data;
  } catch (error: any) {
    throw new ResponseError(error);
  }
};
