import { ICustomer } from 'src/models/ICustomer';

import { getCurrentFiscalYear } from '../../../src/utils/helpers/dateFormatters';
import request from '../request';

export const searchUsers = async (query = ''): Promise<ICustomer[]> => {
  const { data } = await request.get(`/users?search=${query}`);

  //   const { data } = await request.get(
  //     `/users/list-users/get-auto-complete?search=${query}`,
  //   );

  return data.data;
};

export const listUsers = async (query = ''): Promise<ListUsersResponse> => {
  //   const { data } = await request.get(`/users/list-users${query}`);
  const searchParams = new URLSearchParams(query);
  const hasYear = searchParams.has('tax_year');
  if (!hasYear) {
    searchParams.append('tax_year', getCurrentFiscalYear().toString());
  }
  const { data } = await request.get(`/users?${searchParams.toString()}`);

  return data;
};

export const getTaskHistory = async (
  id: number,
): Promise<TaskHistoryData[]> => {
  const { data } = await request.get(`/users/task-history?user_id=${id}`);

  return data.data;
};

export const getNotificationsList = async (ids: number[]) => {
  const { data } = await request.post(
    `/users/list-users/get-notification-counts`,
    {
      user_ids: [...ids],
    },
  );

  return data.data;
};

export const deleteUser = async (userId: number) => {
  const { data } = await request.delete(
    `/users/delete-account?user_id=${userId}`,
  );

  return data;
};

// add disable enpoint
/* export const disableUser = async (userId: number) => {
  const { data } = await request.delete(
    `/users/add disable enpoint here ?user_id=${userId}`,
  );

  return data;
}; */

export const setYearClosed = async (
  userId: number,
  taxYear: number,
  status: boolean,
) => {
  const { data } = await request.post(`/users/set-year-closed`, {
    user_id: userId,
    tax_year: taxYear,
    status,
  });

  return data;
};

export const enableUserAccount = async (userId: number) => {
  const { data } = await request.put(
    `/users/enable-account/?user_id=${userId}`,
  );

  return data;
};

export const enableUserDisabled = async (userId: number) => {
  const { data } = await request.put(`/users/change-status/?user_id=${userId}`);

  return data;
};

export const postToggleSaction = async (user_id: number[]) => {
  const { data } = await request.post(`/sanction/toggle-sanction`, {
    user_id,
  });

  return data.data;
};

type ContactStatus = {
  user_id: number;
  tax_year: number;
  status_label_ids: number[];
};

export const addContactStatus = async ({
  user_id,
  tax_year,
  status_label_ids,
}: ContactStatus) => {
  const { data } = await request.post(`/users/add-contact-status`, {
    user_id,
    tax_year,
    status_label_ids,
  });

  return data.data;
};
export const getUsersByAssistant = async (assistant_id: number) => {
  const { data } = await request.get(
    `/users/by-assistant?assistant_id=${assistant_id}`,
  );

  return data.data;
};

export const getTotalUsersInfoByAssistant = async (assistant_id: number) => {
  const { data } = await request.get(
    `/users/by-assistant/total-users-info?assistant_id=${assistant_id}`,
  );

  return data;
};
