export enum CustomerTabs {
  TASK_HISTORY = 'task_history',
  TAX_INCOME = 'tax_income',
  INCOMES = 'incomes',
  TAX_EXPENSE = 'tax_expense',
  TAX_EXPENSE_NEW = 'tax_expense_new',
  HMRC = 'hmrc',
  INBOX = 'inbox',
  REFERRALS = 'referrals',
  ADMIN_NOTES = 'admin_notes',
  ESIGNATURE = 'esignature',
  DETAILS = 'details',
  TAX_CALC = 'tax_calc',
  FORMS = 'forms',
}

export enum PayUnpayActions {
  InProgress = 'paid',
  Paid = 'unpaid',
}

export enum PayUnpayStatus {
  Blank = 'Blank',
  PaymentDue = 'PaymentDue',
  Paid = 'Paid',
  InProgress = 'InProgress',
}

export enum ProofTitle {
  VERIFIED = 'verified',
  NOT_VERIFIED = 'not verified',
}

export enum CustomerEmployment {
  SELF = 1,
  PAYE = 2,
}
