import { ExpensesApi } from 'src/api';
import { action, observable, makeObservable } from 'src/modules';

export default class ExpensesStore {
  @observable
  data: TaxIncomeListTypes = {
    data: [],
    editable: false,
    gross_total: '',
    message: '',
    tax_total: '',
  };

  constructor() {
    makeObservable(this);
  }

  @action
  fetchCustomerIncomes = async (
    userId: number,
    taxYear: number,
  ): Promise<void> => {
    const response = await ExpensesApi.fetchCustomerExpenses(userId, taxYear);

    this.data = response;
  };

  @action
  submitIncome = async (userId: number, taxYear: number): Promise<void> => {
    await ExpensesApi.submitExpense(userId, taxYear);
  };
}
