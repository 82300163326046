import request from './request';
import ResponseError from './ResponseError';

export const getDeletedAccounts = async (
  page: number,
): Promise<CustomerAccountDeletesAPI> => {
  try {
    // const { data } = await request.get(`/deleted-accounts?page=${page}`);
    const { data } = await request.get(`/users?status=Delete&page=${page}`);

    return data;
  } catch (error: any) {
    throw new ResponseError(error);
  }
};

export const getDeletedAccountByName = async (name: string) => {
  const { data } = await request.get(`/users?status=Delete&search=${name}`);

  return data;
};
