import moment from 'moment';

//-> 07/12/2021
export const formatToScreenDate = (date: Date | string): string => {
  const month = new Date(date).getMonth() + 1;

  return `${month < 10 ? '0' + month : month}/${new Date(
    date,
  ).getDate()}/${new Date(date).getFullYear()} `;
};

//-> 15:23
export const formatToScreenHour = (date: Date | string): string => {
  return `${new Date(date).getHours()}:${new Date(date).getMinutes()}`;
};

export const conversationTime = (date: Date | string): string => {
  const output = 'DD/MM HH:mm';
  return moment(date).format(output);
};

export const formatDate = (
  date: string | Date,
  input = 'DD/MM/YYYY',
  output = 'DD/MM/YYYY',
): string => {
  if (!date) return '';
  return moment(date, input).format(output);
};

export const formatDateFullDate = (
  date: string | Date,
  input = 'YYYY-MM-DD HH:mm:ss',
  output = 'DD/MM/YYYY',
): string => {
  if (!date) return '';
  return moment(date, input).format(output);
};

export const getTaxYearStart = (year: number): string => `${year - 1}-04-06`;

export const getTaxYearEnd = (year: number): string => `${year}-04-05`;

export const getCurrentFiscalYear = (): number => {
  const currentDate = moment();
  const currentYear = currentDate.year();
  const isBeforeApril = currentDate.isBefore(getTaxYearEnd(currentYear));
  const fiscalYear = isBeforeApril ? currentYear - 1 : currentYear;
  return fiscalYear || 2023;
};

export const getYearsList = () => {
  const first = 2015;
  const last = getCurrentFiscalYear() + 1;
  const years = [];
  for (let index = last; index >= first; index--) {
    years.push(index);
  }

  return years;
};
