export enum Routes {
  // PUBLIC ROUTES
  APP = '/app/',
  LOGIN = '/login',

  // PRIVATE ROUTES
  EXPENSES = '/app/expenses',
  ADMINS = '/app/admins',
  ADD_ADMIN = '/app/admins/add',
  ADMIN_USER_EDIT = '/app/admin-user-edit',
  TEAMS = '/app/admins/teams',
  CATEGORIES = '/app/categories',
  CATEGORY_ADD = '/app/category-add',
  COST_SUGGESTION = '/app/cost-suggestion',
  CUSTOMER_INFORMATION = '/app/customer-information',
  CUSTOMERS = '/app/customers',
  CUSTOMERS_CREATE = '/app/customers/create',
  EDIT_PROFILE = '/app/edit-profile',
  EXTERNAL_EXPENSE_FORMS_INFORMATION = '/app/external-expense-forms-information',
  EXTERNAL_EXPENSE_FORMS = '/app/external-expense-forms',
  HOW_YOU_HERE = '/app/how-you-here',
  JOB_CATEGORIES = '/app/job-categories',
  JOB_SUB_CATEGORIES = '/app/job-sub-categories',
  MAIL_SETTINGS = '/app/mail-settings',
  NOTIFICATIONS = '/app/notifications',
  MESSAGES = '/app/messages',
  QUESTION_LIBRARY = '/app/question-library',
  SETTINGS = '/app/settings',
  STATUS_TAGS = '/app/status-tags',
  USER_ACCOUNT_DELETES = '/app/user-account-deletes',
  USER_REFERRALS = '/app/user-referrals',
  NOTIFCATION_TEMPLATES = '/app/notification-templates',
  NOTIFCATION_TEMPLATES_EDIT = '/app/notification-templates/edit',
  INBOX_TEMPLATES = '/app/inbox-templates',
  INBOX_TEMPLATES_EDIT = '/app/inbox-templates/edit',
  INBOX_TEMPLATES_CREATE = '/app/inbox-templates/create',
  DASHBOARD_METRICS = '/app/dashboard',
  DASHBOARD_STAFF = '/app/dashboard/staff',
  DASHBOARD_SHIFT = '/app/dashboard/staff-shift',
  UTILS = '/app/utils',
  CHANGELOG = '/app/changelog',
  UTR_INSTALL = '/app/utr-install',
  HOME = '/app/home',
  ASSISTANT_CHANGES = '/app/logs/assistant-changes',
  QUICK_REPLIES = '/app/messages-quick-replies',
  SANCTION_LIST = '/app/sanction-list',
  CUSTOMERS_PER_PAGE = '/app/admins/customers-per-page',
  CUSTOMERS_CONTINGENCY = '/app/customers-contingency',
}
