import { AuthApi } from 'src/api';
import { action, computed, observable, makeObservable } from 'src/modules';
import { Storage } from 'src/services';

export default class AuthStore {
  @observable
  userData: User = {
    id: 0,
    name: '',
    email: '',
    image: '',
    role_id: 0,
    user_rule: '',
  };

  constructor() {
    makeObservable(this);
  }

  @computed
  get getUserName(): string {
    return this.userData.name;
  }

  @action
  getUser = async () => {
    if (this.userData.email) return this.userData;

    const user = await Storage.getUser();

    this.userData = user;

    return user;
  };

  @action
  setUser = async (user: User): Promise<void> => {
    await Storage.setUser(user);

    this.userData = user;
  };

  @action
  login = async (credentials: Credentials): Promise<void> => {
    const { access_token, user } = await AuthApi.login(credentials);

    this.setUser(user);

    Storage.setToken(access_token);
    await Storage.setUser(user);
  };

  @action
  fetchMe = async (): Promise<User> => {
    const response = await AuthApi.fetchMe();

    this.userData = response;
    await Storage.setUser(response);
    return response;
  };

  @action
  logout = (): void => {
    Storage.clearWholeStorage();
  };
}
