// Returns either a value or undefined (to remove it from querystring)
export const getQueryParam = (value: any) => {
  if (value === '') return undefined;
  return value;
};

export const isValidData = (value: any) => {
  if (typeof value === 'string') {
    if (value === 'undefined' || value === 'null' || value === 'false')
      return false;
  }
  return true;
};

export const stringHasValue = (value: string): boolean => {
  return !!(value && value.length > 0);
};

export const validCount = (value: any, greaterThan = 0): boolean => {
  if (!value || isNaN(value)) return false;

  return Number(value) > greaterThan;
};

export const highlightText = (query: string, text: string) => {
  if (!text || !query) return '';
  const regex = new RegExp(query.replace(' ', '.*'), 'gi');
  return text.replace(regex, `<span class="text-mark">${query}</span>`);
};

// Check if the value is neither null nor undefined
export const notNullOrUndefined = (value: unknown) => {
  if (value === undefined || value === null) {
    return false;
  }

  return true;
};

export const validNumber = (value: any): boolean => {
  if (isNaN(value)) return false;
  if (value === '') return false;
  return true;
};

const currencyUK = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
});

const numberUK = new Intl.NumberFormat('en-GB');

export const formatCurrency = (value?: number | string) => {
  if (!value) return `£0`;
  // check if value is a string and may or may not have the currency symbol
  if (typeof value === 'string') {
    value = Number(value.replace(/£/g, ''));
  }

  return currencyUK.format(value);
};

export const formatAmount = (value?: number) => {
  if (!value) return `0`;
  return numberUK.format(value);
};

export const isTruthy = (value: any) => {
  if (!value) return false;
  if (value == 1 || value == true) return true;
  if (String(value).toLowerCase() == 'yes') return true;
  return !!value;
};

export const isFalsy = (value: any) => {
  if (!value) return true;
  if (value == 0) return true;
  if (String(value).toLowerCase() == 'no') return true;
  if (value === '') return true;
  return false;
};

// Sum up and Array of numbers
export const arraySum = (array: number[]) => {
  return array.reduce((a, b) => a + b, 0);
};

export const arraySumParam = (array: any[], param: any) => {
  const result = array.reduce((a, b) => a + b[param], 0);
  return result;
};

export const arraySumParamSplit = (array: any[], param: any) => {
  const result = array.reduce((a, b) => a + b.values[0][param], 0);
  return result;
};

export const isValidEmail = (email: string) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

// Minimum eight characters, at least one uppercase letter, one lowercase letter and one number:
export const isValidPassword = (password: string) => {
  const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
  return re.test(password);
};

type ORDER_BY = 'ASC' | 'DESC';
export const sortArrayByKey = <T>(
  array: any[T],
  key: string,
  order: ORDER_BY = 'ASC',
) => {
  return array.sort((a: any, b: any) => {
    if (order === 'ASC') {
      return a[key] > b[key] ? 1 : -1;
    } else {
      return a[key] < b[key] ? 1 : -1;
    }
  });
};

export const isValidPhoneNumber = (value: string) => {
  if (!value || value.length < 6) return false;
  if (value.charAt(0) !== '+') return false;

  return true;
};

export const createMarkup = (text: string) => {
  return { __html: text };
};
