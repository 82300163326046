import { action, observable, makeObservable } from 'mobx';

import { CustomerApi } from 'src/api';
import {
  getUsersByAssistant,
  getTotalUsersInfoByAssistant,
} from 'src/api/users';
import { ICustomerData } from 'src/models';
import { TaxStatus } from 'src/utils';
import {
  CustomerCreateData,
  CustomerInformation,
} from 'src/utils/types/Customer';

export default class CustomerStore {
  @observable
  customers: ICustomerData = {
    data: [],
    has_more: false,
    total_pages: 0,
    total_entries: 0,
    next_page: 0,
    next_page_url: '',
    previous_page_url: '',
  };

  @observable
  customerData: CustomerInformation = {
    id: 0,
    prefix: '',
    profile_pic: '',
    full_name: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    utr_no: '',
    email: '',
    status: '',
    employee_type: '',
    phone: '',
    insurance_no: '',
    requested_utr: '',
    dob: '',
    user_referral_code: '',
    company_name: '',
    company_logo: '',
    company_address: '',
    company_postal_code: '',
    vat_no: '',
    account_holder_name: '',
    account_sort_code: '',
    account_number: '',
    id_proof: '',
    id_proof_title: '',
    referred_by: '',
    postal_code: '',
    address: '',
    tax_year: 0,
    how_you_are_here: '',
    how_you_here_image: '',
    tax_assistant_image: '',
    tax_status: TaxStatus.InProgress,
    income_status: '',
    income_received_date: '',
    income_completed_date: '',
    expense_status: '',
    expense_received_date: '',
    expense_completed_date: '',
    is_called: false,
    is_paid_status: 'Blank',
    tax_code_complete: false,
    pre_payment_status: 0,
    refund_amount: 0,
    agent_fee: 0,
    notifications_count: 0,
    agent_fee_type: '',
    agent_fee_percentage: 0,
    vat_amount: 0,
    vat_percentage: 0,
    pre_pay_agent_fee: 0,
    pre_pay_agent_fee_type: '',
    pre_pay_agent_fee_percentage: 0,
    pre_pay_vat_percentage: 0,
    pre_pay_vat_amount: 0,
    paid_date: '',
    estimated_refund_date: '',
    send_notifications: false,
    send_sms: false,
    send_email: false,
    last_login: '',
    user_tax_modified_date: '',
    created_ip: '',
    tax_status_process_date: '',
    tax_status_paid_date: '',
    is_paye_employee: false,
    has_dual_employee: false,
    status_info: [],
    message: '',
    is_multiple_locations: false,
    is_pub_priv_travel: false,
    reach_by: '',
    paye_expenses_new_total: 0,
  };

  @observable
  notificationsCountChanged = false;

  constructor() {
    makeObservable(this);
  }

  @action
  fetchCustomers = async (search?: string): Promise<ICustomerData> => {
    const response = await CustomerApi.fetchCustomers(search);

    this.customers = response;
    return response;
  };

  @action
  setCustomerInformation = (info: CustomerInformation) => {
    this.customerData = info;
  };

  @action
  getCustomerInformation = async (
    userId: number,
    taxYear: number,
  ): Promise<void> => {
    const response = await CustomerApi.getCustomerInformation(userId, taxYear);

    this.setCustomerInformation(response);
  };

  @action
  setTaxStatusBlank = async (
    userId: number,
    taxYear: number,
  ): Promise<void> => {
    const { is_paid_status } = await CustomerApi.setTaxStatusBlank(
      [userId],
      taxYear,
    );

    const newData = this.customerData;
    newData.is_paid_status = is_paid_status;

    this.setCustomerInformation(newData);
  };

  @action
  setTaxStatusPaid = async (userId: number, taxYear: number): Promise<void> => {
    const { is_paid_status } = await CustomerApi.setTaxStatusPaid(
      [userId],
      taxYear,
    );

    this.customerData.is_paid_status = is_paid_status;
  };

  @action
  setMultipleTaxStatusBlank = async (userId: number[]): Promise<void> => {
    await CustomerApi.setMultipleTaxStatusBlank(userId);
  };

  @action
  setTaxPaymentDue = async (userId: number, taxYear: number): Promise<void> => {
    const { is_paid_status } = await CustomerApi.setTaxPaymentDue(
      [userId],
      taxYear,
    );

    this.customerData.is_paid_status = is_paid_status;
  };

  @action
  setMultipleTaxStatusPaid = async (userId: number[]): Promise<void> => {
    await CustomerApi.setMultipleTaxStatusPaid(userId);
  };

  @action
  setMultipleTaxPaymentDue = async (userId: number[]): Promise<void> => {
    await CustomerApi.setMultipleTaxPaymentDue(userId);
  };

  @action
  cancelPaymentDue = async (userId: number, taxYear: number): Promise<void> => {
    const { is_paid_status } = await CustomerApi.cancelPaymentDue(
      userId,
      taxYear,
    );

    this.customerData.is_paid_status = is_paid_status;
  };

  @action
  createCustomer = async (data: CustomerCreateData): Promise<void> => {
    await CustomerApi.createCustomer(data);
  };

  @action
  updateUserDetails = async (userId: number, data: CustomerInformation) => {
    await CustomerApi.updateUserDetails(userId, data);
  };

  @action
  getHowYouHere = () => {
    return CustomerApi.getHowYouHere();
  };

  @action
  onNotificationsCountChanged = () => {
    this.notificationsCountChanged = !this.notificationsCountChanged;

    return this.notificationsCountChanged;
  };

  @action
  getUsersByAssistant = (assistant_id: number) => {
    return getUsersByAssistant(assistant_id);
  };

  @action
  getTotalUsersInfoByAssistant = (assistant_id: number) => {
    return getTotalUsersInfoByAssistant(assistant_id);
  };
}
