export const getInitials = (name = ''): string => {
  if (!name) return '';
  const space = name?.lastIndexOf(' ');
  const last = name.substr(space + 1, 1);
  const first = name.substr(0, 1);

  return `${first}${last}`;
};

export const getValidData = (value: any) => {
  if (!value || value === 'null') {
    return '';
  }
  return value;
};
