import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { isEmpty } from 'lodash';

import { Storage } from '../services';
import { ExpiredSessionError } from '../utils';

interface CustomRequest {
  authorization?: string;
}

export const DEFAULT_TIMEOUT = 3000;

export const successResponse = (response: any): AxiosResponse => response;

export const addAuthHeaders = async (
  config: AxiosRequestConfig,
): Promise<AxiosRequestConfig & CustomRequest> => {
  const token: string | null = Storage.getToken();
  if (!token || isEmpty(token)) {
    return config;
  }
  return {
    ...config,
    headers: {
      ...config.headers,
      token: `${token}`,
      authorization: `Bearer ${token}`,
    },
  };
};

export const verifyExpiredToken = (
  error: ErrorResponse,
): ExpiredSessionError | ErrorResponse => {
  const expiredSessionError = 401;
  if (error.response.status === expiredSessionError) {
    Storage.clearWholeStorage();
    throw new ExpiredSessionError();
  }
  throw error;
};
