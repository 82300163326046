import request from '../request';

export const fetchCustomerIncomes = async (
  userId: number,
  taxYear: number,
): Promise<TaxIncomeListTypes> => {
  const { data } = await request.get(
    `/users/tax-income/listing/?user_id=${userId}&tax_year=${taxYear}`,
  );
  return data;
};

export const submitIncome = async (
  userId: number,
  taxYear: number,
): Promise<void> => {
  const data = {
    user_id: userId,
    tax_year: taxYear,
  };
  await request.post(`/users/tax-income/submit`, data);
};

export const exportIncomes = async (userId: number, taxYear: number) => {
  const { data } = await request.get(
    `/users/tax-income/export?user_id=${userId}&tax_year=${taxYear}`,
  );

  return data.data;
};

export const deleteIncome = async ({ id }: { id: number }): Promise<void> => {
  await request.delete(`/users/tax-income/remove?id=${id}`);
};
