import { QueryFunctionContext } from 'react-query';

import {
  MessageCenterConversation,
  SendMessageCenterParams,
} from 'src/utils/types/MessageCenter';

import request from '../request';
import requestMessageCenter from '../requestMessageCenter';

export const getConversations = async ({ queryKey }: QueryFunctionContext) => {
  const [
    ,
    tax_assistant_id,
    page = 1,
    sort_type,
    message_type_id,
    searchText = '',
    unread,
  ] = queryKey;

  const params =
    message_type_id || sort_type || unread
      ? { message_type_id, sort_type, unread }
      : {};

  if (tax_assistant_id) {
    const { data } = await requestMessageCenter.get(
      `/conversations?per_page=30&page=${page}&tax_assistant_id=${tax_assistant_id}`,
      { params },
    );

    return data;
  }
  const { data } = await requestMessageCenter.get(
    `/conversations?per_page=30&page=${page}&search=${searchText}`,
    { params },
  );

  return data;
};

export const getConversationMessages = async (
  page = 1,
  conversationId = 1,
  messageTypeId?: number,
): Promise<MessageCenterConversation> => {
  let result;

  if (messageTypeId) {
    result = await requestMessageCenter.get(
      `/conversations/messages?page=${page}&per_page=30&conversation_id=${conversationId}&message_type_id=${messageTypeId}`,
    );
  } else {
    result = await requestMessageCenter.get(
      `/conversations/messages?page=${page}&per_page=30&conversation_id=${conversationId}`,
    );
  }

  return result.data;
};

export const postMarkRead = async (conversation_body_id: number) => {
  const { data } = await requestMessageCenter.post(`/conversations/mark-read`, {
    conversation_body_id,
  });

  return data;
};

export const getConversationByUserId = async (user_id: number) => {
  const { data } = await requestMessageCenter.get(
    `/conversations?per_page=30&page=${1}&user_id=${user_id}`,
  );

  return data;
};

export const sendMessage = async ({
  customer_id,
  message_body,
  message_type_id,
  conversation_id,
  files,
  is_new_message,
}: SendMessageCenterParams) => {
  const formData = new FormData();

  formData.append('message_type_id', String(message_type_id));
  formData.append('message_body', message_body);
  formData.append('customer_id', String(customer_id));

  if (!is_new_message) {
    formData.append('conversation_id', String(conversation_id));
  }

  if (files) {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      formData.append('files[]', file);
    }
  }

  const { data } = await requestMessageCenter.post(
    `/conversations/send-message`,
    formData,
  );

  return data;
};

export const postSendMessage = async ({
  customer_id,
  message_body,
  message_type_id,
  conversation_id,
}: SendMessageCenterParams) => {
  const { data } = await requestMessageCenter.post(
    `/conversations/send-message`,
    {
      customer_id,
      message_body,
      message_type_id,
      conversation_id,
    },
  );

  return data;
};

export const PostLinkGuestToUser = async ({
  conversation_id,
  customer_id,
}: {
  conversation_id: number;
  customer_id: number;
}) => {
  const { data } = await requestMessageCenter.post(
    `/conversations/link-guest-to-user`,
    {
      conversation_id,
      customer_id,
    },
  );

  return data;
};

export const getQuickReplies = async () => {
  const { data } = await request.get(`/quick-reply`);

  return data;
};

export const postQuickReply = async (title: string) => {
  const { data } = await request.post(`/quick-reply/create`, {
    title,
  });

  return data;
};

export const editQuickReply = async ({
  id,
  title,
}: {
  id: number;
  title: string;
}) => {
  const { data } = await request.patch(`/quick-reply/update?id=${id}`, {
    title,
  });

  return data;
};

export const deleteQuickReply = async ({ id }: { id: number }) => {
  const { data } = await request.delete(`/quick-reply/delete/?id=${id}`);

  return data;
};

export const getMessagesQuickReplies = async () => {
  const { data } = await requestMessageCenter.get(
    `/conversations/list-templates?type=QuickReply`,
  );

  return data;
};

export const getFilesAndImages = async (conversationId: number) => {
  const { data } = await requestMessageCenter.get(
    `/conversations/files?conversation_id=${conversationId}`,
  );

  return data;
};

// Voice Message API requests

// Get twiio token, 1 token is valid for 1 hour
export const getTwilioToken = async () => {
  const { data } = await requestMessageCenter.post(
    `/conversations/voice/token`,
  );

  return data.token || null;
};

export const updateIncomeYear = async ({
  id,
  taxYear,
}: {
  id: number;
  taxYear: number;
}) => {
  const { data } = await requestMessageCenter.patch(
    `/conversations/update-income-year?id=${id}&tax_year=${taxYear}`,
  );

  return data;
};

export const deleteMessage = async ({
  conversationBodyId,
}: {
  conversationBodyId: number;
}) => {
  const { data } = await requestMessageCenter.delete(
    `/conversations/delete-message`,
    {
      data: {
        conversation_body_id: conversationBodyId,
      },
    },
  );

  return data;
};

export const updateMessage = async ({
  converstationBodyId,
  messageBody,
}: {
  converstationBodyId: number;
  messageBody: string;
}) => {
  const { data } = await requestMessageCenter.put(
    `/conversations/edit-message`,
    {
      conversation_body_id: converstationBodyId,
      message_body: messageBody,
    },
  );

  return data;
};
